import React, { useState, useEffect, useCallback } from "react";

import * as paper from "paper";
import * as jsPDF from "jspdf";
import "./Editor.styles.scss";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
} from "reactstrap";
import MeasurementForm from "../MeasurementForm/MeasurementForm";

const printCoverPercentage = 0.85;
const printFormats = {
  a4: {
    name: "a4",
    // width: 25.47,
    // height: 16.82,
    width: 29.7,
    height: 21.0,
  },
};

export default function Editor({ measurements, onSetMeasurements }) {
  const [showingMeasurements, setShowMeasurements] = useState(false);

  const showMeasurements = () => setShowMeasurements(!showingMeasurements);

  const printScale = 96 / 2.54;
  const scale = 10; // FIXME autofit
  const ease = 0.25;
  const neckDartDepth = 7;
  const neckDartAdjustment = 0.7;
  const dpi = 3;
  const printFormat = printFormats.a4;

  const [backDart, setBackDart] = useState(3);
  const [shoulderDart, setShoulderDart] = useState(1);
  const [frontDart, setFrontDart] = useState(4);

  const download = (e) => {
    console.log("downloading");
    e.preventDefault();

    let canvas = document.getElementById("draft");
    let pdfOpts = {
      format: printFormat.name,
      orientation: "landscape",
      unit: "cm",
    };
    var drawing_width = measurements.bust / 2;
    var drawing_height = measurements.centreBack;
    var PDF_Width = printFormat.width * printCoverPercentage;
    var PDF_Height = printFormat.height * printCoverPercentage;
    let marginWidth = (printFormat.width * (1 - printCoverPercentage)) / 2;
    let marginHeight = (printFormat.height * (1 - printCoverPercentage)) / 2;
    var canvas_image_width = paper.view.bounds.width;
    var canvas_image_height = paper.view.bounds.height;
    var vertical_sections = Math.ceil(drawing_height / PDF_Height);
    var horizontal_sections = Math.ceil(drawing_width / PDF_Width);

    let startPos = paper.project.activeLayer.bounds.topLeft;
    let pages = new paper.Group();
    for (var i = 0; i <= horizontal_sections; i++) {
      pages.addChild(
        new paper.Path(
          startPos.add(i * PDF_Width, 0),
          startPos.add(i * PDF_Width, vertical_sections * PDF_Height)
        )
      );
    }
    for (var j = 0; j <= vertical_sections; j++) {
      pages.addChild(
        new paper.Path(
          startPos.add(0, j * PDF_Height),
          startPos.add(horizontal_sections * PDF_Width, j * PDF_Height)
        )
      );
    }
    pages.strokeColor = "red";
    pages.dashArray = [1 / scale, 1 / scale];
    pages.strokeWidth = 1 / scale;

    paper.view.pivot = paper.view.bounds.topLeft;
    paper.view.draw();
    setTimeout(() => {
      let imgData = canvas.toDataURL();

      var pdf;
      for (var i = 1; i <= vertical_sections; i++) {
        for (var j = 1; j <= horizontal_sections; j++) {
          if (pdf) {
            pdf.addPage(pdfOpts);
          } else {
            pdf = new jsPDF(pdfOpts);
          }
          let x = -(PDF_Width * j - PDF_Width);
          let y = -(PDF_Height * i - PDF_Height);
          pdf.addImage(
            imgData,
            "JPG",
            x + marginWidth,
            y + marginHeight,
            canvas_image_width,
            canvas_image_height
          );
        }
      }

      pdf.save("custom-bodice.pdf");
      pages.removeChildren();
      paper.view.draw();
    }, 100);
  };

  const handleResize = useCallback((e) => {
    // Whenever the window is resized, recenter the path:
    paper.project.position = paper.view.bounds.topLeft;
  }, []);

  useEffect(() => {
    paper.setup("draft");

    window.addEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    paper.project.activeLayer.removeChildren();
    paper.view.draw();

    let bodiceBackPath = new paper.Path();
    let bodiceFrontPath = new paper.Path();

    let a = new paper.Point(0, 0);
    let a1 = a.add(measurements.bust / 20 + 2.5, 0);
    let backNeckPassthrough = a1.subtract(
      1.0606601717798214,
      1.0606601717798214
    ); // FIXME this isnt right

    let a12 = a.add(3, 0);
    let a1l = a1.subtract(0, measurements.bust / 40 + 0.3);
    let a1ll = a1l.subtract(0, 1);

    let b = a.add(0, measurements.centreBack / 2 + 1);
    let b2 = b.add(measurements.acrossBack / 2 + ease, 0);
    let a3 = new paper.Point(b2.x, 0);
    let a3l = a3.add(0, measurements.bust / 40 - 1.5);
    let va1la3l = a3l.subtract(a1l);
    va1la3l.length = measurements.shoulder + shoulderDart + 0.5;
    let a3ll = a1l.add(va1la3l);
    let a2l = a1l.add(
      new paper.Point((a3ll.x - a1l.x) / 2, (a3ll.y - a1l.y) / 2)
    );
    let b1 = b2.divide(new paper.Point(2, 1));

    // Neck dart
    let va2lb1 = b1.subtract(a2l);
    va2lb1.length = neckDartDepth;
    let a2 = a2l.add(va2lb1);
    let vNeckDart = a2l.subtract(a1l);
    vNeckDart.length = shoulderDart / 2;
    let a2lPredart = a2l.add(vNeckDart);
    let a2lPostdart = a2l.subtract(vNeckDart);
    let va2lPredart = a2.subtract(a2lPredart);
    va2lPredart.length = neckDartAdjustment;
    a2lPredart = a2lPredart.subtract(va2lPredart);
    let va2lPostdart = a2.subtract(a2lPostdart);
    va2lPostdart.length = neckDartAdjustment;
    a2lPostdart = a2lPostdart.subtract(va2lPostdart);

    let b4 = b2.add(measurements.bust / 8 + ease, 0);
    let b6 = b4.add((measurements.bust / 20) * 4 + 0.25, 0);
    let a7 = new paper.Point(b6.x, a1ll.y);
    let a6 = a7.subtract(measurements.bust / 20 + 2.25, 0);
    let a4 = new paper.Point(b4.x, a1ll.y);
    let a5 = a4.add(measurements.bust / 20 + 2, 0);
    let a4l = a4.add(0, measurements.bust / 20);
    let va5a4l = a4l.subtract(a5);
    va5a4l.length = measurements.shoulder;
    let a4ll = a5.add(va5a4l);
    let va4lla5 = a4ll.subtract(a5);
    va4lla5.length = measurements.shoulder / 2;
    let a52 = a5.add(va4lla5);
    let a8 = a7.add(0, measurements.bust / 20 + 2.75);
    let a8l = new paper.Point(a6.x, a8.y);
    let va8la7 = a7.subtract(a8l);
    va8la7.length = va8la7.length / 3;
    let frontNeckPassthrough = a8l.add(va8la7);
    let b5 = b6.subtract(measurements.apexapex / 2, 0);
    let b5l = b5.add(0, 1);
    let b3 = b4.subtract((b4.x - b2.x) / 2, 0);
    let b2l = a3.add(0, (b2.y - a3.y) / 2 + 3);
    let vb2 = b2.clone();
    vb2.angle = -45;
    vb2.length = 2.8;
    let backArmPassthroughBottom = b2.add(vb2);
    let backArmPassthroughTop = b2l
      .subtract(b2l.subtract(a3ll).divide(2))
      .subtract(0.3, 0);
    let b4l = b4.subtract(0, (b4.y - a4.y) / 3);
    let vb4 = b4.clone();
    vb4.angle = 45;
    vb4.length = 2.5;
    let frontArmPassthroughBottom = b4.subtract(vb4);
    let va4llb4l = b4l.subtract(a4ll).divide(2);
    let frontArmPassthroughTop = a4ll.add(va4llb4l.x + 0.3, va4llb4l.y);

    let c = new paper.Point(0, measurements.centreBack);
    let c2 = c.add(new paper.Point(measurements.waist / 4 + 0.75 + 0.5, 0));
    let c1 = new paper.Point(b1.x, c.y);
    let c5 = b6.add(0, measurements.centreBack / 2 - 1);
    let c3 = c5.subtract(measurements.waist / 4 - 0.75 + 0.5 + 4, 0);
    let c4 = b5.add(0, measurements.centreBack / 2 - 1);
    let c4predart = c4.subtract(frontDart / 2, 0);
    let c4postdart = c4.add(frontDart / 2, 0);

    // Back
    bodiceBackPath.moveTo(b3);
    bodiceBackPath.curveTo(backArmPassthroughBottom, b2l);
    bodiceBackPath.curveTo(backArmPassthroughTop, a3ll);
    bodiceBackPath.lineTo(a2lPredart);
    bodiceBackPath.lineTo(a2);
    bodiceBackPath.lineTo(a2lPostdart);
    bodiceBackPath.lineTo(a1l);
    bodiceBackPath.curveTo(backNeckPassthrough, a12, 0.4);
    bodiceBackPath.lineTo(a);
    bodiceBackPath.lineTo(b);
    bodiceBackPath.lineTo(c);
    bodiceBackPath.lineTo(c1.subtract(backDart / 2, 0));
    bodiceBackPath.lineTo(b1);
    bodiceBackPath.lineTo(c1.add(backDart / 2, 0));
    bodiceBackPath.lineTo(c2);
    bodiceBackPath.lineTo(b3);

    // Front
    bodiceFrontPath.moveTo(b3);
    bodiceFrontPath.lineTo(c3);
    bodiceFrontPath.lineTo(c4predart);
    bodiceFrontPath.lineTo(b5l);
    bodiceFrontPath.lineTo(c4postdart);
    bodiceFrontPath.lineTo(c5);
    bodiceFrontPath.lineTo(a8);
    bodiceFrontPath.curveTo(frontNeckPassthrough, a6);
    bodiceFrontPath.lineTo(a5);
    bodiceFrontPath.lineTo(a4l); // FIXME half measurements.shoulder
    bodiceFrontPath.lineTo(a4ll);
    bodiceFrontPath.curveTo(frontArmPassthroughTop, b4l);
    bodiceFrontPath.curveTo(frontArmPassthroughBottom, b3);

    bodiceBackPath.strokeColor = "black";
    bodiceFrontPath.strokeColor = "black";

    let guides = new paper.Group();
    // Guides
    addGuide(b, b6, guides);
    addGuide(c, c5, guides);
    addGuide(a1ll, a7, guides);
    addGuide(a, a3, guides);
    addGuide(a3, b2, guides);
    addGuide(b4, a4, guides);
    addGuide(c5, a7, guides);
    addGuide(a1, a1ll, guides);
    addGuide(a1l, a3ll, guides);

    addGuide(a1, backNeckPassthrough, guides);
    addGuide(b2, backArmPassthroughBottom, guides);
    addGuide(b4, frontArmPassthroughBottom, guides);

    // Darts
    addGuide(a2, a2l, guides);
    addGuide(b1, c1, guides);
    addGuide(c4, b5l, guides);
    addGuide(a5, b5l, guides);
    addGuide(a6, b5l, guides);
    addGuide(a52, b5l, guides);

    addGuide(a6, a8l, guides);
    addGuide(a8l, a8, guides);
    addGuide(a8l, a7, guides);
    guides.strokeColor = "darkgray";

    let scalePath = new paper.Path.Rectangle(new paper.Point(0, -2), 1);
    scalePath.strokeColor = "black";

    paper.view.scaling = scale;
    paper.project.activeLayer.strokeWidth = 1 / scale;
    paper.project.activeLayer.pivot = paper.project.activeLayer.bounds.topLeft;
    paper.project.activeLayer.position = paper.view.bounds.topLeft;

    paper.view.draw();
  }, [backDart, frontDart, measurements, shoulderDart]);

  const addGuide = (p1, p2, group) => {
    let g = new paper.Path(p1, p2);
    g.dashArray = [2 / scale, 2 / scale];
    group.addChild(g);
  };

  return (
    <div className="d-flex flex-column h-100">
      <Navbar color="secondary" light expand="md">
        <NavbarBrand>
          <img src="/logo_150.png" height="30px" /> Draft My Size
        </NavbarBrand>

        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink
              href="#"
              onClick={showMeasurements}
              className="btn btn-link"
            >
              Change measurements
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" onClick={download} className="btn btn-primary">
              Download PDF
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
      <div className="workspace">
        <canvas id="draft" resize="true"></canvas>
      </div>

      <Modal isOpen={showingMeasurements} toggle={showMeasurements}>
        <ModalHeader toggle={showMeasurements}>
          Measurements (in cm)
        </ModalHeader>
        <ModalBody>
          <MeasurementForm
            measurements={measurements}
            onSetMeasurements={(measurements) => {
              onSetMeasurements(measurements);
              showMeasurements();
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
