import React, { useState } from "react";
// import Intro from "../../components/Intro/Intro";
import Intro from "../../components/Intro/Intro";
import Editor from "../../components/Editor/Editor";
import "./App.scss";

function App() {
  const [measurements, setMeasurements] = useState({
    bust: 95,
    waist: 70,
    apexapex: 18.5,
    centreBack: 42,
    shoulder: 12,
    acrossBack: 35,
  });
  const [showDrawing, setShowDrawing] = useState(false);

  return (
    <div className="App">
      {showDrawing ? (
        <Editor
          measurements={measurements}
          onSetMeasurements={(measurements) => {
            setMeasurements(measurements);
          }}
        />
      ) : (
        <Intro
          measurements={measurements}
          onSetMeasurements={(measurements) => {
            setMeasurements(measurements);
            setShowDrawing(true);
          }}
        />
      )}
    </div>
  );
}

export default App;
