import React from "react";
import MeasurementsForm from "../MeasurementForm/MeasurementForm";
import NeckbandCalculator from "../NeckbandCalculator/NeckbandCalculator";
import { Jumbotron, Container } from "reactstrap";
import "./Intro.styles.scss";

export default function Intro({ onSetMeasurements, measurements }) {
  return (
    <>
      <Jumbotron fluid>
        <Container>
          <div className="d-flex  align-items-center justify-content-around">
            <img src="/logo_150.png" alt="Logo" className="" />
            <h1>Draft My Size</h1>
          </div>
          <div>
            <p className="lead">
              Generate a custom fit sewing block at the click of a button{" "}
            </p>
            <hr className="my-4" />
            <p>
              Get a perfect fit every time by using your custom fitted sloper
              for drafting patterns and making alteration.
            </p>
          </div>
        </Container>
      </Jumbotron>
      <div className="measurements text-center pb-4">
        <Container>
          <h2>Your measurements (cm)</h2>
          <MeasurementsForm
            measurements={measurements}
            onSetMeasurements={onSetMeasurements}
          />
        </Container>
      </div>
      <div className="neckband text-center py-4">
        <Container>
          <NeckbandCalculator />
        </Container>
      </div>
      <div className="credits py-4">
        <Container>
          <h2>Credits</h2>
          <p>
            These patterns are based on the wonderful guides provided by Diane
            Déziel at{" "}
            <a
              href="https://dianedezie7.wixsite.com/toolfully"
              rel="noopener noreferrer"
              target="_blank"
            >
              Toolfully
            </a>
          </p>
          <p>
            Are you a knitter? Draft My Size is the little sister to{" "}
            <a href="https://www.chart-minder.com">Chart Minder</a>, where you
            can find and draw your own knitting patterns.
          </p>
        </Container>
      </div>
      <div className="faq py-4">
        <Container>
          <h2>What is a block for?</h2>
          <p>
            A master block, sometimes called a 'master pattern', 'basic block',
            or 'sloper', is considered the parent pattern from which you draft
            your garments and drafted to a perfect fit of your model. The block
            lacks intricate design details or styling; there are no fancy
            necklines, pockets, ruffles, pleats, gathers of any kind. The only
            purpose of a block is to fit the figure or the dress form properly.
          </p>
        </Container>
      </div>
      <div className="footer p-2 text-center">
        <Container>
          <p>&copy; Abby Millward {new Date().getFullYear()}</p>
        </Container>
      </div>
    </>
  );
}
