import React, { useState } from "react";
import { FormGroup, Button, Form, Label, Input } from "reactstrap";

export default function MeasurementForm({ measurements, onSetMeasurements }) {
  const [bust, setBust] = useState(measurements.bust);
  const [waist, setWaist] = useState(measurements.waist);
  const [acrossBack, setAcrossBack] = useState(measurements.acrossBack);
  const [apexapex, setApexApex] = useState(measurements.apexapex);
  const [shoulder, setShoulder] = useState(measurements.shoulder);
  const [centreBack, setCentreBack] = useState(measurements.centreBack);

  const formatNumber = (num) => {
    let str = parseFloat(num) || 0;
    let idx = num.indexOf(".");
    if (idx > -1 && idx !== num.length - 1) {
      return parseFloat(str.toFixed(2)) || 0;
    }
    return num;
  };
  return (
    <Form>
      <div className="measurements text-center d-flex flex-wrap justify-content-around">
        <FormGroup>
          <Label>Bust</Label>
          <Input
            type="text"
            value={bust}
            onChange={(e) => setBust(formatNumber(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label>Waist</Label>
          <Input
            type="text"
            value={waist}
            onChange={(e) => setWaist(formatNumber(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label>Across back</Label>
          <Input
            type="text"
            value={acrossBack}
            onChange={(e) => setAcrossBack(formatNumber(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label>Apex to apex</Label>
          <Input
            type="text"
            value={apexapex}
            onChange={(e) => setApexApex(formatNumber(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label>Shoulder</Label>
          <Input
            type="text"
            value={shoulder}
            onChange={(e) => setShoulder(formatNumber(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label>Centre back</Label>
          <Input
            type="text"
            value={centreBack}
            onChange={(e) => setCentreBack(formatNumber(e.target.value))}
          />
        </FormGroup>
      </div>
      <Button
        size="lg"
        color="primary"
        onClick={() =>
          onSetMeasurements({
            bust,
            waist,
            shoulder,
            acrossBack,
            centreBack,
            apexapex,
          })
        }
      >
        Draft My Size
      </Button>
    </Form>
  );
}
